import { ReactNode, useState } from 'react';

import { DataBreadcrumbItem, DataCategory } from 'data/Category';
import { LanguageLocalizations } from 'data/Language';
import Seo from 'components/Seo';
import Navigation from 'components/navigation/Navigation';
import Footer from 'components/footer/Footer';

interface LayoutProps {
  categories: DataCategory[];
  localizations: LanguageLocalizations;
  children: ReactNode;
  title?: string;
  description?: string;
  image?: string;
  breadcrumb?: DataBreadcrumbItem[];
}

export default function Layout({
  children,
  title,
  description,
  image,
  categories,
  localizations,
  breadcrumb,
}: LayoutProps) {
  const [navDesktopEnabled, setNavDesktopEnabled] = useState(true);
  return (
    <div
      className={`Layout ${
        !navDesktopEnabled ? 'Layout--navigationSmall' : ''
      }`}
    >
      <div id="outdated">
        <h6>Váš prohlížeč je zastaralý!</h6>
        <p>
          Pro správné zobrazení těchto stránek aktualizujte svůj prohlížeč.
          <a id="btnUpdateBrowser" href="http://outdatedbrowser.com/cs">
            Aktualizovat prohlížeč nyní
          </a>
        </p>
      </div>
      <Seo title={title} description={description} image={image} />
      <Navigation
        categories={categories}
        localizations={localizations}
        breadcrumb={breadcrumb}
        onRender={setNavDesktopEnabled}
      />
      {children}
      <Footer categories={categories} />
    </div>
  );
}
