import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { DataCategory } from 'data/Category';
import { Routes } from 'utils/routes';

interface FooterProps {
  categories: DataCategory[];
}

function Footer({ categories }: FooterProps) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  return (
    <div className="Footer">
      <div className="Container">
        <div className="Column">
          <Link href={Routes.homepage} passHref>
            <a className="Footer__brand">{t('NAVIGATION_TITLE')}</a>
          </Link>
          <div className="Footer__info">
            {t('NAVIGATION_DESCRIPTION')}
            <br />
            {locale === 'cz' && (
              <>
                <Link href={Routes.accessibility} passHref>
                  <a className="Footer__infoLink">Prohlášení o přístupnosti</a>
                </Link>
                <br />
              </>
            )}
            <a className="Footer__infoLink" href="mailto:info@idecin.cz">
              info@idecin.cz
            </a>
            <br />
            <a className="Footer__infoLink" href="tel:+420412532227">
              +420 412 532 227
            </a>
            <div className="Footer__infoSocial">
              <a
                className="Footer__infoSocialLink Footer__infoSocialLink--instagram"
                rel="noopener noreferrer"
                href="https://www.instagram.com/idecin.cz"
                target="_blank"
              >
                idecin.cz
              </a>
              <a
                className="Footer__infoSocialLink Footer__infoSocialLink--facebook"
                rel="noopener noreferrer"
                href="https://www.facebook.com/decinproturisty"
                target="_blank"
              >
                Děčín pro turisty
              </a>
              <a
                className="Footer__infoSocialLink Footer__infoSocialLink--youtube"
                rel="noopener noreferrer"
                href="https://www.youtube.com/@decinproturisty5656"
                target="_blank"
              >
                YouTube
              </a>
            </div>
          </div>
          <a
            href="https://forcandies.com"
            target="_blank"
            rel="noopener noreferrer"
            className="Footer__author"
          >
            <div className="Footer__authorCopyright">
              &copy; {new Date().getFullYear()} {t('CREATED_BY')}{' '}
              <strong>forCandies</strong>
            </div>
          </a>
        </div>
        <div className="Column Footer__menu">
          {categories.map(({ children, locale: categoryLocale, uid }) => (
            <div key={uid} className="Footer__section">
              <Link href={categoryLocale.path} passHref>
                <a className="Footer__sectionTitle">{categoryLocale.title}</a>
              </Link>
              {children.map(({ locale, uid }) => (
                <Link key={uid} href={`${categoryLocale.path}#${locale.slug}`}>
                  <a className="Footer__sectionLink">{locale.title}</a>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Footer;
