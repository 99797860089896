import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import {
  Language,
  LanguageItem,
  LanguageLocalizations,
  LANGUAGES,
} from 'data/Language';
import useOnClickOutside from 'hooks/useOnClickOutside';

interface NavigationLanguageProps {
  localizations: LanguageLocalizations;
}

function NavigationLanguage({ localizations }: NavigationLanguageProps) {
  const [opened, setOpened] = useState(false);
  const [language, setLanguage] = useState<LanguageItem>(LANGUAGES[0]);
  const { locale, push } = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpened(false));

  useEffect(() => {
    const lang = LANGUAGES.find(l => l.code === locale);
    if (!lang) {
      return;
    }

    setLanguage(lang);
  }, [locale]);

  const changeLanguage = useCallback(
    async (url: string, code: string) => {
      setOpened(false);
      await push(url, url, { locale: code });
    },
    [push],
  );

  return (
    <div
      className={classNames('Navigation__language', {
        'Navigation__language--opened': opened,
      })}
      ref={ref}
    >
      <button
        type="button"
        className={`Navigation__languageToggle Navigation__languageToggle--${language.code}`}
        onClick={() => setOpened(!opened)}
      >
        {language.name}
      </button>
      <div className="Navigation__languageDropdown">
        {LANGUAGES.map(({ name, code }) => {
          const link = localizations[code as Language];
          if (!link) {
            return (
              <div
                key={code}
                className={`Navigation__languageDropdownItem Navigation__languageDropdownItem--${code} Navigation__languageDropdownItem--disabled`}
              >
                {name}
              </div>
            );
          }

          return (
            <a
              key={code}
              onClick={() => changeLanguage(link, code)}
              className={`Navigation__languageDropdownItem Navigation__languageDropdownItem--${code}`}
            >
              {name}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default NavigationLanguage;
