import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import Head from 'next/head';

interface SeoProps {
  title?: string;
  description?: string;
  image?: string;
}

function Seo({ title, description, image }: SeoProps) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const images = image
    ? [{ url: image }]
    : [{ url: `${process.env.NEXT_PUBLIC_BASE_URL}/social.jpg` }];
  const [seoTitle, seoOgTitle] = createSeoTitles(t, title);
  const [seoDescription, seoOgDescription] = createSeoDescription(
    t,
    description,
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          description: seoOgDescription,
          title: seoOgTitle,
          images,
          url: `${process.env.NEXT_PUBLIC_BASE_URL}${router?.asPath}`,
        }}
      />
    </>
  );
}

export default Seo;

function createSeoTitles(t: TFunction, pageName?: string): [string, string] {
  let title = '';
  let ogTitle = '';

  if (pageName) {
    title = pageName + ' • ';
    ogTitle = pageName + ' • ';
  }

  title += t('NAVIGATION_DESCRIPTION');
  ogTitle += t('NAVIGATION_TITLE');

  return [title, ogTitle];
}

function createSeoDescription(
  t: TFunction,
  pageDescription?: string,
): [string, string] {
  if (!pageDescription) {
    return [t('DESCRIPTION'), t('DESCRIPTION')];
  }

  return [pageDescription, pageDescription];
}
