import { useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import jQuery from 'jquery';

import { DataCategory } from 'data/Category';
import { Routes } from 'utils/routes';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useWindowSize } from 'hooks/useWindowSize';

interface NavigationMenuProps {
  categories: DataCategory[];
  onReload: (desktop: boolean) => void;
}

function NavigationMenu({ categories, onReload }: NavigationMenuProps) {
  const { t } = useTranslation('common');
  const innerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  const [categoryUid, setCategoryUid] = useState<string | undefined>(undefined);
  useOnClickOutside(innerRef, () => setCategoryUid(undefined));

  const calculateMenu = useCallback(() => {
    const inner = innerRef.current;
    if (!width || !inner) {
      return;
    }

    // 24:padding + 212:brand + 24:padding + 48:search + 24:padding + 8:magic
    const w = width - 24 - (width < 980 ? 162 : 212) - 24 - 48 - 24 - 8;

    let items = 0;
    Object.values(jQuery(inner).find('.Navigation__menuItem')).forEach(item => {
      if (item.offsetWidth !== undefined) {
        items += item.offsetWidth;
      }
    });

    items = Math.ceil(items);
    items += 40; // magic

    onReload(items < w);
  }, [onReload, width]);

  const calculateDropdown = useCallback(() => {
    const dropdown = dropdownRef.current;
    const scroll = scrollRef.current;
    if (!dropdown || !scroll) {
      return;
    }

    let width = 24;
    Object.values(jQuery(scroll).find('.Navigation__menuSection')).forEach(
      item => {
        if (item.offsetWidth !== undefined) {
          width += item.offsetWidth;
        }
      },
    );

    scroll.style.width = `${width}px`;
  }, []);

  useEffect(() => setCategoryUid(undefined), [width]);
  useEffect(() => {
    calculateMenu();
    calculateDropdown();
  }, [width, categoryUid, calculateMenu, calculateDropdown]);

  return (
    <div className="Navigation__menu">
      <div ref={innerRef}>
        {categories.map((category, key) => {
          const isFirstCategory = key === 0;
          const children = category.children.map((section, key) => {
            const isFirstSection = key === 0;
            const pages = section.pages.map((page, key) => (
              <Link href={page.locale.path} key={key} passHref>
                <a
                  className="Navigation__menuSectionLink"
                  title={page.locale.title}
                  onClick={() => setCategoryUid(undefined)}
                >
                  {page.locale.title}
                </a>
              </Link>
            ));

            if (isFirstCategory && isFirstSection) {
              pages.push(
                <Link key={pages.length} href={Routes.news}>
                  <a
                    className="Navigation__menuSectionLink"
                    onClick={() => setCategoryUid(undefined)}
                  >
                    {t('NEWS_TITLE')}
                  </a>
                </Link>,
              );
            }

            let path = section.breadcrumb[0].path + '#' + section.locale.slug;
            return (
              <div key={key} className="Navigation__menuSection">
                <Link href={path} passHref>
                  <a
                    className="Navigation__menuSectionTitle"
                    onClick={() => setCategoryUid(undefined)}
                  >
                    {section.locale.title}
                  </a>
                </Link>
                {pages}
              </div>
            );
          });

          return (
            <div
              key={key}
              className={classNames('Navigation__menuDropdown', {
                'Navigation__menuDropdown--opened':
                  categoryUid === category.uid,
              })}
              ref={categoryUid === category.uid ? dropdownRef : null}
            >
              <div
                className="Navigation__menuItem Navigation__menuItem--dropdown"
                onClick={() => setCategoryUid(category.uid)}
              >
                {category.locale.title}
              </div>
              <div className="Navigation__menuDropdownContainer">
                <div className="Navigation__menuDropdownShadow">
                  <div className="Navigation__menuDropdownScroll">
                    <div
                      className="Navigation__menuDropdownScrollContent"
                      ref={categoryUid === category.uid ? scrollRef : null}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="Navigation__menuDropdown">
          <a
            href="https://idecin.shop"
            target="_blank"
            rel="noopener noreferrer"
            className="Navigation__menuItem Navigation__menuItem--external"
          >
            E-shop
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavigationMenu;
