import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { SearchResult, SearchResultNews, SearchResultPage } from 'data/Search';
import { useWindowSize } from 'hooks/useWindowSize';
import useOnClickOutside from 'hooks/useOnClickOutside';

function NavigationSearch() {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const searchRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { width } = useWindowSize();

  useOnClickOutside(searchRef, () => {
    setOpened(false);
    setQuery('');
    setPages([]);
    setNews([]);
  });

  const [opened, setOpened] = useState(false);
  const [query, setQuery] = useState('');

  const [pages, setPages] = useState<SearchResultPage[]>([]);
  const [news, setNews] = useState<SearchResultNews[]>([]);

  useEffect(() => {
    if (query.length === 0) {
      setPages([]);
      setNews([]);
      return;
    }

    fetch(
      `/api/search?locale=${locale}&query=${encodeURIComponent(query)}`,
    ).then(async res => {
      const { pages, news } = (await res.json()) as SearchResult;
      setPages(pages);
      setNews(news);
    });
  }, [locale, query, width]);

  const toggle = useCallback(() => {
    setOpened(!opened);
    setQuery('');
    setPages([]);
    setNews([]);

    setTimeout(() => inputRef.current?.focus(), 100);
  }, [opened, inputRef]);

  return (
    <form ref={searchRef} action="#" onSubmit={e => e.preventDefault()}>
      <div className="Navigation__search">
        <button
          type="button"
          className="Navigation__searchIcon"
          onClick={toggle}
        >
          {t('NAVIGATION_SEARCH')}
        </button>
      </div>
      <div
        className={classNames('Navigation__searchField', {
          'Navigation__searchField--opened': opened,
        })}
      >
        <label
          className={'Navigation__searchFieldInputLabel'}
          htmlFor={'search'}
        >
          {t('NAVIGATION_SEARCH_PLACEHOLDER')}
        </label>
        <input
          id={'search'}
          type="search"
          className="Navigation__searchFieldInput"
          placeholder={t('NAVIGATION_SEARCH_PLACEHOLDER')}
          ref={inputRef}
          value={query}
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              return true;
            }
            toggle();
          }}
          onChange={e => setQuery(e.target.value)}
        />
        <button
          type="button"
          className="Navigation__searchFieldClose"
          onClick={toggle}
        >
          {t('NAVIGATION_CLOSE')}
        </button>
        <button type="button" className="Navigation__searchFieldConfirm">
          {t('NAVIGATION_SEARCH')}
        </button>
      </div>
      <div
        className={classNames('Navigation__searchResults', {
          'Navigation__searchResults--opened':
            pages.length > 0 || news.length > 0,
        })}
      >
        <div className="Navigation__searchResultsShadow">
          <div className="Navigation__searchResultsScroll">
            {pages.length > 0 && (
              <div className="Navigation__searchResultsSection">
                <div className="Navigation__searchResultsSectionTitle">
                  {t('NAVIGATION_SEARCH_PAGES')}
                </div>
                {pages.map(({ uid, locale }) => (
                  <Link href={locale.path} key={uid} passHref>
                    <a className="Navigation__searchResultsItem">
                      <div className="Navigation__searchResultsItemTitle">
                        {locale.title}
                      </div>
                      {locale.subtitle}
                    </a>
                  </Link>
                ))}
              </div>
            )}
            {news.length > 0 && (
              <div className="Navigation__searchResultsSection">
                <div className="Navigation__searchResultsSectionTitle">
                  {t('NAVIGATION_SEARCH_NEWS')}
                </div>
                {news.map(({ title, subtitle, path, date_published }) => (
                  <Link key={path} href={path} passHref>
                    <a className="Navigation__searchResultsItem">
                      <small>{date_published}</small>
                      <div className="Navigation__searchResultsItemTitle">
                        {title}
                      </div>
                      {subtitle}
                    </a>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default NavigationSearch;
