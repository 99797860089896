export type Language = 'cz' | 'de' | 'en';

export interface LanguageItem {
  code: string;
  name: string;
}

export interface LanguageLocalizations {
  cz: string | null;
  de: string | null;
  en: string | null;
}

export const LANGUAGES: LanguageItem[] = [
  { code: 'cz', name: 'Česky' },
  { code: 'de', name: 'Deutsch' },
  { code: 'en', name: 'English' },
];
